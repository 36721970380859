export const REPORT_TITLES = {
  // ALTICE NEWS
  ALTICE_BFMTV: 'BFMTV',
  ALTICE_BFM_REGIONS: 'BFM Régions',
  ALTICE_RMC: 'RMC',
  ALTICE_RMC_SPORT: 'RMC Sport',

  // ALTICE MONETISATION
  ALTICE_BFMTV_MONETISATION: 'BFMTV (Monétisation)',
  ALTICE_RMC_MONETISATION: 'RMC (Monétisation)',
  ALTICE_RMC_SPORT_MONETISATION: 'RMC Sport (Monétisation)',

  // ALTICE ENTERTAINMENT
  ALTICE_RMC_ENTERTAINMENT: 'RMC Entertainment',

  // LE PARISIEN
  LEPARISIEN: 'Le Parisien',

  // LOOPSIDER
  LOOPSIDER: 'Loopsider',

  // REWORLD
  REWORLD: 'Reworld',
  REWORLD_MELTY: 'Melty',
  REWORLD_AUFEMININ: 'Au Féminin',
  REWORLD_GRAZIA: 'Grazia',
  REWORLD_DOCTISSIMO: 'Doctissimo',
  REWORLD_MARMITON: 'Marmiton',
  REWORLD_MINUTE_BUZZ: 'Minute Buzz',

  // EBRA
  EBRA: 'Ebra',

  // CARREFOUR
  CARREFOUR: 'Carrefour',
}

// ALTICE NEWS MONTHLY
export const ALTICE_NEWS_BFMTV_MONTHLY = {
  dashboardData: {
    graphs: {
      donut_views: {
        graph_type: 'donut',
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_NEWS_RMC_MONTHLY = {
  dashboardData: {
    graphs: {
      donut_views: {
        graph_type: 'donut',
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
}
export const ALTICE_NEWS_RMC_SPORT_MONTHLY = {
  dashboardData: {
    graphs: {
      donut_views: {
        graph_type: 'donut',
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
}
export const ALTICE_NEWS_MONEY_BFMTV_MONTHLY = {
  dashboardData: {
    graphs: {
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      views_60s: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_60s: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_time_completion: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_NEWS_MONEY_RMC_MONTHLY = {
  dashboardData: {
    graphs: {
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      views_60s: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_60s: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_time_completion: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_NEWS_MONEY_RMC_SPORT_MONTHLY = {
  dashboardData: {
    graphs: {
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      views_60s: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_60s: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_time_completion: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    /*tables: {
            videos_revenues: {
                limit: 3
            },
        }*/
  },
} // OK
export const ALTICE_NEWS_REGIONS_MONTHLY = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      // revenues: {
      //   graph_type: 'bar',
      //   stacked: true
      // },
      donut_views: {
        graph_type: 'donut',
        stacked: false,
      },
      // donut_revenues: {
      //   graph_type: 'donut',
      //   stacked: false
      // },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      short_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
}
// ALTICE NEWS HEBDO
export const ALTICE_NEWS_BFMTV_HEBDO = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK

export const ALTICE_NEWS_AUDIENCE_REGION_BFMTV_HEBDO = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      // revenues: {
      //   graph_type: 'bar',
      //   stacked: true
      // },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK

export const ALTICE_NEWS_RMC_HEBDO = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
}
export const ALTICE_NEWS_RMC_SPORT_HEBDO = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
}
export const ALTICE_NEWS_MONEY_BFMTV_HEBDO = {
  dashboardData: {
    graphs: {
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      views_60s: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_60s: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_time_completion: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_NEWS_MONEY_RMC_HEBDO = {
  dashboardData: {
    graphs: {
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      views_60s: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_60s: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_time_completion: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_NEWS_MONEY_RMC_SPORT_HEBDO = {
  dashboardData: {
    graphs: {
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      views_60s: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_3m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_60s: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_over: {
        graph_type: 'bar',
        stacked: true,
      },
      count_8m_under: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_revenues: {
        limit: 5,
      },
      videos_time_completion: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_NEWS_REGIONS_HEBDO = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      // revenues: {
      //   graph_type: 'bar',
      //   stacked: true
      // },
      donut_views: {
        graph_type: 'donut',
        stacked: false,
      },
      // donut_revenues: {
      //   graph_type: 'donut',
      //   stacked: false
      // },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      short_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
}

// ALTICE ENTERTAINMENT
export const ALTICE_ENTERTAINMENT_DAILY = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK
export const ALTICE_ENTERTAINMENT_HEBDO = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
}
export const ALTICE_ENTERTAINMENT_MONTHLY = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      // minutes_viewed: {
      //     data: 'videos',
      //     graph_type: 'line',
      //     stacked: false
      // },
      // views_60s: {
      //     data: 'videos',
      //     graph_type: 'line',
      //     stacked: false
      // },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      cpm: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
    },
    // BENCHMARK
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      cpm: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
    },
    // BENCHMARK
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reel_count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
    },
  },
  snapchatData: {
    graphs: {
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 5,
      },
    },
  },
} // OK

// REWORLD Media
export const REWORLD = {
  dashboardData: {
    graphs: {
      // views_organic: {
      views: {
        graph_type: 'bar',
        stacked: true,
        // custom: {
        //   FB: 'views_organic',
        //   SC: 'reach'
        // }
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        snap_unique_views: true,
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        graph_type: 'bar',
        stacked: true,
      },
      views_organic: {
        graph_type: 'area',
        stacked: true,
        organic_paid: ['views_organic', 'views_paid'],
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'published_at',
        order: 'DESC',
      },
      videos_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'published_at',
        order: 'ASC',
      },
      reels_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'published_at',
        order: 'DESC',
      },
      reels_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'published_at',
        order: 'ASC',
      },
      revenues_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'revenues',
        order: 'DESC',
      },
      videos_interactions_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'interactions',
        order: 'DESC',
      },
      /*videos_interactions_flop: {
        limit: 3,
        start_from: "created_time",
        type: "flop",
        order_by: "interactions",
        order: "ASC",
      },
      topByCreatedTime: {
        limit: 3
      },
      topRevenues: {
        limit: 3
      },
      topFlop: {
        limit: 3
      }*/
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
        euro: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'published_at',
        order: 'DESC',
      },
      videos_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'published_at',
        order: 'ASC',
      },
      shorts_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'published_at',
        order: 'DESC',
      },
      shorts_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'published_at',
        order: 'ASC',
      },
      revenues_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'revenues',
        order: 'DESC',
      },
      videos_interactions_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'interactions',
        order: 'DESC',
      },
      /*videos_interactions_flop: {
        limit: 3,
        start_from: "created_time",
        type: "flop",
        order_by: "interactions",
        order: "ASC",
      },
      topByCreatedTime: {
        limit: 3
      },
      topFlop: {
        limit: 3
      },
      topRevenues: {
        limit: 3
      },
      topInteractions: {
        limit: 3
      }*/
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'created_time',
        order: 'DESC',
      },
      videos_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'created_time',
        order: 'ASC',
      },
      videos_interactions_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'interactions',
        order: 'DESC',
      },
      /*videos_interactions_flop: {
        limit: 3,
        start_from: "created_time",
        type: "flop",
        order_by: "interactions",
        order: "ASC",
      },*/
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count', 'image_count', 'carousel_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'created_time',
        order: 'DESC',
      },
      reels_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'created_time',
        order: 'ASC',
      },
      videos_interactions_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'interactions',
        order: 'DESC',
      },
      /*videos_interactions_flop: {
        limit: 3,
        start_from: "created_time",
        type: "flop",
        order_by: "interactions",
        order: "ASC",
      },*/
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'created_time',
        order: 'DESC',
      },
      stories_flop: {
        limit: 3,
        start_from: 'created_time',
        type: 'flop',
        order_by: 'created_time',
        order: 'ASC',
      },
      videos_interactions_top: {
        limit: 3,
        start_from: 'created_time',
        type: 'top',
        order_by: 'interactions',
        order: 'DESC',
      },
      /*videos_interactions_flop: {
        limit: 3,
        start_from: "created_time",
        type: "flop",
        order_by: "interactions",
        order: "ASC",
      },*/
    },
  },
}

// LE PARISIEN
export const LEPARISIEN = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        graph_type: 'line',
        stacked: false,
      },
      //followers: {
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count' /*, 'image_count', 'link_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      shorts: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count' /*, 'image_count', 'carousel_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}
// LOOPSIDER
export const LOOPSIDER = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count' /*, 'image_count', 'link_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      shorts: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count' /*, 'image_count', 'carousel_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}
// EBRA
export const EBRA = {
  dashboardData: {
    graphs: {
      views: {
        snap_unique_views: true,
        graph_type: 'bar',
        stacked: true,
      },
      /*revenues: {
        graph_type: 'bar',
        stacked: true
      },*/
      impressions: {
        snap_unique_views: true,
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //   graph_type: 'line',
      //   stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      views_organic: {
        graph_type: 'area',
        stacked: true,
        organic_paid: ['views_organic', 'views_paid'],
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
        dashboardDataBehaviour: true,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      // detailed_interactions: {
      //   data: 'content_type',
      //   graph_type: 'bar',
      //   stacked: true,
      //   content_type: ['likes', 'shares', 'clics', 'comments']
      // },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      reels: {
        limit: 5,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
        dashboardDataBehaviour: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count', 'image_count', 'carousel_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 5,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
        dashboardDataBehaviour: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
        dashboardDataBehaviour: true,
      },
      /*revenues: {
        graph_type: 'bar',
        stacked: true
      },*/
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      // detailed_interactions: {
      //   data: 'content_type',
      //   graph_type: 'bar',
      //   stacked: true,
      //   content_type: ['likes', 'shares', 'clics', 'comments']
      // },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 5,
      },
      shorts: {
        limit: 5,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}

// CARREFOUR
export const CARREFOUR = {
  dashboardData: {
    graphs: {
      views: {
        snap_unique_views: true,
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        snap_unique_views: true,
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      shorts: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count', 'image_count', 'carousel_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}

// PRISMA
export const PRISMA = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        graph_type: 'line',
        stacked: false,
      },
      //followers: {
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count' /*, 'image_count', 'link_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      shorts: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count' /*, 'image_count', 'carousel_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}

// EDITIS
export const EDITIS = {
  dashboardData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      shorts: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count', 'image_count' /*'carousel_count'*/],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}

// DEMO_GENERAL
export const DEMO_GENERAL = {
  dashboardData: {
    graphs: {
      views: {
        snap_unique_views: true,
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        snap_unique_views: true,
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        data: 'content_type',
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'reel_count', 'image_count', 'link_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  youtubeData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['video_count', 'short_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      shorts: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  tiktokData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      videos: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  instagramData: {
    graphs: {
      views: {
        data: 'videos',
        graph_type: 'bar',
        stacked: true,
      },
      reach: {
        graph_type: 'line',
        stacked: false,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
        content_type: ['reel_count', 'image_count', 'carousel_count'],
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      reels: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
  snapchatData: {
    graphs: {
      top_snap_unique_views: {
        graph_type: 'bar',
        stacked: true,
      },
      unique_viewers: {
        graph_type: 'line',
        stacked: false,
      },
      revenues: {
        graph_type: 'bar',
        stacked: true,
      },
      count: {
        graph_type: 'bar',
        stacked: true,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      interaction_rate: {
        graph_type: 'line',
        stacked: false,
      },
    },
    tables: {
      stories: {
        limit: 3,
      },
      videos_interactions: {
        limit: 3,
      },
    },
  },
}

export const PRISMA_MENSUEL = {
  display_metrics_table: true,
  dashboardData: {
    graphs: {
      views: {
        snap_unique_views: true,
        graph_type: 'bar',
        stacked: true,
      },
      impressions: {
        snap_unique_views: true,
        graph_type: 'line',
        stacked: false,
      },
      followers_fans: {
        graph_type: 'line',
        stacked: false,
      },
      total_interactions: {
        graph_type: 'line',
        stacked: false,
      },
      // followers: {
      //     graph_type: 'line',
      //     stacked: false
      // },
    },
  },
  facebookData: {},
  instagramData: {},
  tiktokData: {},
  youtubeData: {},
  snapchatData: {},
}

